import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonPrimary from "../../button-primary";
import Input from "./input";
import TextArea from "./text-area";

const ChanneledHandwrittenLetterOrderForm = ({ button_title, onSubmit }) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <TextArea
            label="Message"
            placeholder="Message"
            rows={15}
          />
        </div>
        <ButtonPrimary type="submit" title={button_title} />
      </form>
    </FormProvider>
  );
};

export default ChanneledHandwrittenLetterOrderForm;
