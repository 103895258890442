function getIsFreeSpotAvailable() {
  return Promise.resolve({ isFreeSpotAvailable: Math.random() > 0.5 });
}

function placeOrder() {
  return Promise.resolve(true);
}

function notifyMe(email) {
  return Promise.resolve(true);
}

const handwrittenLetterApi = {
  placeOrder,
  getIsFreeSpotAvailable,
  notifyMe,
};
export default handwrittenLetterApi;
