import React, { useEffect, useState } from "react";
import HtmlContent from "../../../html-content";
import handwrittenLetterApi from "../../../../services/handwritten-letter-api";
import NotifyMeForm from "../../../notify-me-form";
import ChanneledHandwrittenLetterOrderForm from "../../order-form";
import useIsServiceAvailable from "../../../../hooks/useIsServiceAvailable";
import useCheckoutSessionStatus from "../../../../hooks/useCheckoutSessionStatus";
import { CheckoutForm } from "../../../checkout";

const DefaultContent = ({ text, submitButtonTitle, product }) => {
  const { isServiceAvailable, reasonUnavailable } =
    useIsServiceAvailable(product);

  const [message, setMessage] = useState(null);

  const configuration = product.configuration;
  const { status } = useCheckoutSessionStatus(configuration);

  const onPlaceOrderSubmit = async (data) => {
    try {
      setMessage(data.message);
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  };

  return (
    <>
      <div className="container ">
        <HtmlContent content={text} />
        <div className="mt-4">
          {isServiceAvailable && (
            <>
              {status === "complete" || status === "open" || message ? (
                <CheckoutForm
                  orderData={{ message }}
                  returnUrlSlug={"handwritten-letter"}
                  product={product}
                />
              ) : (
                <ChanneledHandwrittenLetterOrderForm
                  onSubmit={onPlaceOrderSubmit}
                  button_title={submitButtonTitle}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div className="mt-3">
        <NotifyMeForm
          product={product}
          signupSuccessfulMessage={
            "Thank you for signing up. You will be notified when new spots are available"
          }
        />
      </div>
    </>
  );
};

export default DefaultContent;
