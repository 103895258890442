import React from "react";
import { useFormContext } from "react-hook-form";
import { camelize } from "../../../../utils/string";

const TextArea = ({ label, placeholder, rows, required }) => {
  const { register } = useFormContext();
  const id = camelize(label);

  return (
    <>
      {" "}
      <label className="form-label">{label}</label>
      <textarea
        {...register(id, { required })}
        className="form-control"
        placeholder={placeholder}
        rows={rows}
      ></textarea>
    </>
  );
};

export default TextArea;
