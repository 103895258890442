import React from "react";
import { useFormContext } from "react-hook-form";
import { camelize } from "../../../../utils/string";

const Input = ({ label, type, placeholder, required }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const isInvalid = !!errors[label];

  const id = camelize(label);
  return (
    <>
      <label className="form-label">{label}</label>
      {/* TODO: remove harcoded required */}
      <input
        {...register(id, { required: false })}
        id={id}
        aria-invalid={isInvalid ? "true" : "false"}
        type={type}
        placeholder={placeholder}
        className="form-control"
      />
      {isInvalid && (
        <div className="alert alert-danger" role="alert">
          {label} is required
        </div>
      )}
    </>
  );
};

export default Input;
