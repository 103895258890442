import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import { graphql } from "gatsby";
import Banner from "../../components/banner";
import DefaultContent from "../../components/handwritten-letter/content/default";

// eslint-disable-next-line no-unused-vars
import * as PageSeoFragment from "../../fragments/pageSeo";
// eslint-disable-next-line no-unused-vars
import * as productFragment from "../../fragments/product";
import wrapWithLayout from "../../components/layout/wrapWithLayout";

// TODO: stripe loading slow. smaller image
// TODO: success message after form submission is only text on page

const ChanneledHandwrittenLetter = wrapWithLayout(({ data }) => {
  const banner = data?.strapiChanneledHandwrittenLetterPage?.banner;
  const text = data?.strapiChanneledHandwrittenLetterPage?.text;
  const button_title = data?.strapiChanneledHandwrittenLetterPage?.button_title;
  const product = data?.strapiChanneledHandwrittenLetterPage?.product;

  return (
    <>
      {banner && <Banner {...banner} />}
      <div className="mt-4 mt-md-5">
        <DefaultContent
          text={text}
          product={product}
          submitButtonTitle={button_title}
        />
      </div>
    </>
  );
});

export default ChanneledHandwrittenLetter;

export const query = graphql`
  query {
    strapiChanneledHandwrittenLetterPage {
      seo {
        ...PageSeoFragment
      }
      banner {
        ...BannerFragment
      }
      button_title
      text {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      product {
        ...ProductFragment
      }
    }
  }
`;
